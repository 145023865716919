body {
  background-color: white;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 1.5em;
  color:#212121; 
}
button {
  width: 50px;
  padding: 10px;
  margin-top: 10px;
  border: none;
  background-color: rgb(243, 33, 33);
  color: white;
  border-radius: 2px;
  cursor: pointer;
  font-family: Etxeak;
  font-size: 20px;
}

.icon-loading {
  display: flex;
  height: 100vh;
  width: 100vw; 
  justify-content: center;
  align-items: center;
  font-size: 1em;
  /* border: 5px solid red; */
}

.app {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* border: 5px solid red; */
}

.app > * {
  width: 100%;
}

form {
  display: flex;
}

input {
  padding: 10px;
  margin-right: 5px;
  width: 200px;
  outline: none;
  border: 2px solid rgb(243, 58, 33);
  border-width: 0 0 5px 0;
  transition: 0.2s;
  background-color: rgb(255, 255, 255);
  font-family: Etxeak;
  font-size: 15px;
}
input:focus {
  background-color: #FFFF8D
}

.tweets {
  display: flex;
  /* border: 5px solid red; */
  flex-wrap: wrap;
}

.tweets > * {
  width: 100%;
  padding: 3px 10px;
  border-bottom: 1px solid #9E9E9E;
}

.tweets > div > button {
  margin: 0 10px;
  font-size: 0.7em;
}

.tweet {
  line-height: 1.5;
}

h1, h10 {
  font-family: Etxeak;
}

@font-face {
  font-family: 'Etxeak';
  src: url('./fonts/Etxeak.ttf');
  font-weight: normal;
  font-style: normal;
} 